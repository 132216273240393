<template>
  <footer>
    <div class="footer-container">
      <div class="footer-top">
        <div class="footer-section">
          <router-link :to="{ name: 'legal', query: { tab: 'privacy' } }">
            <span class="footer-item">Privacy Policy</span>
          </router-link>
          <!-- <router-link :to="{ name: 'legal', query: { tab: 'terms' } }">
            <span class="footer-item">Terms of Use</span>
          </router-link> -->
          <!-- <router-link :to="{ name: 'contact', params: {} }">
            <span class="footer-item">Contact Us</span>
          </router-link> -->
        </div>
        <div class="footer-section">
          <!-- <router-link :to="{ name: 'info', params: {} }">
            <span class="footer-item">Additional Info</span>
          </router-link> -->
          <!-- <router-link :to="{ name: 'sitemap', params: {} }">
            <span class="footer-item">Sitemap</span>
          </router-link> -->

          <p class="abn"><span class="inline-block w-16">ABN:</span> 61 472 798 811</p>
          <p class="abn">
            <span class="inline-block w-16">Contact:</span> support@peddle.store
          </p>
        </div>
        <!-- <div class="footer-section"></div> -->
      </div>
      <p class="copy-note mt-8">
        Copyright &copy; {{ appName }} {{ new Date().getFullYear() }}. All rights
        reserved.
      </p>
    </div>
  </footer>
</template>

<script>
  export default {
    name: 'Footer',
    data() {
      return {
        appName: 'Peddle'
      };
    }
  };
</script>

<style lang="css" scoped>
  .abn {
    font-size: 0.9rem;
    color: #e0e0e0;
  }
  p {
    color: #ffffff;
  }
  footer {
    background-color: #303846;
    color: #ffffff;
    padding: 20px 0;
    width: 100%;
    margin-top: 50px;
  }
  .footer-container {
    display: flex;
    flex-direction: column;
    width: 90%;
    margin: auto;
    max-width: 1200px;
  }
  .footer-container > p {
    text-align: center;
  }
  .footer-top {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around;
    margin-bottom: 10px;
  }
  .footer-section {
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
  }
  .footer-section a {
    color: #e0e0e0;
    font-family: 'Open Sans', sans-serif;
    font-size: 14px;
    margin: 8px 15px;
  }
  .footer-item {
  }
  footer h4 {
    color: #ffffff;
  }
  .footer-section img {
    width: 25px;
    height: auto;
    margin: 0 10px;
  }
  footer p a {
    color: #bdbdbd;
  }
  .notification {
    border-radius: 2px;
    opacity: 0;
    position: fixed;
    font-size: 17px;
    top: -120px;
    right: 10px;
    color: #fff;
    padding: 5px;
    transition: 0.3s ease;
  }
  .copy-note {
    font-size: 0.9rem;
    color: lightgray;
  }
</style>
