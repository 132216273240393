<template>
  <div class="px-8 py-1 border-b">
    <Logo />
    <nav></nav>
  </div>
</template>

<script>
  import Logo from '@/components/Logo';

  export default {
    components: {
      Logo
    }
  };
</script>

<style scoped></style>
