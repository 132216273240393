<template>
  <div class="flex items-center h-10">
    <router-link :to="{ name: 'home' }" class="h-2/3">
      <img class="h-full" src="/logo.png" />
    </router-link>
  </div>
</template>

<script>
  export default {};
</script>

<style scoped></style>
