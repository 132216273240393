<template>
<div class="flex flex-col h-screen">
    <Nav />
    <div class="flex flex-col flex-grow">
      <h1 style="text-align: center; font-family: helvetica, sans-serif;">404 NOT FOUND</h1>
    </div>
    <Footer />
  </div>
</template>

<style scoped>

</style>

<script>
  import Nav from '@/components/Nav.vue';
  import Footer from '@/components/sections/Footer.vue';

  export default {
    name: "NotFound",
    components: {
      Nav,
      Footer
    }
  };
</script>